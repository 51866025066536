import React, { useCallback, useState } from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { compose, withProps, withStateHandlers } from 'recompose'

const GoogleMapWithMarker = compose(
  withStateHandlers(() => ({
    infoIndex: -1,
  }), {
    updateInfoIndex: () => infoIndex => ({
      infoIndex
    })
  }),
  withScriptjs,
  withGoogleMap
)(({ data, infoIndex, updateInfoIndex }) => {
  const element = JSON.parse(data);
  const marker_items = JSON.parse(element.jsonMap);
  
  const landscape_color = element.LandscapeColor !== null ? element.LandscapeColor : '#333333';
  const poi_color = element.POIColor !== null ? element.POIColor : '#333333';
  const road_color = element.RoadColor !== null ? element.RoadColor : '#ffffff';
  const water_color = element.WaterColor !== null ? element.WaterColor : '#ffffff';
  
  const mapDark = [{
    "featureType": "administrative",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [{ "color": landscape_color }] // config
  }, {
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [{ "color": poi_color }] // config
  }, {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{ "color": road_color }] // config
  }, {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  }, {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [{ "color": water_color }] // config
  }, {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [{ "visibility": "off" }]
  }
  ];
  
  var init_latlng = { lat: -34.4183695090691, lng: 150.8939155495682 };
  if(marker_items.length){
      var first_marker = marker_items[0];
      init_latlng = { lat: parseFloat(first_marker.lat), lng: parseFloat(first_marker.lng) };
  }
  var marker_img = "/img/marker-map-pink.svg";
  const markerColorsMap = {
        "Pink": "/img/marker-map-pink.svg",
        "White": "/img/marker-map-white.svg",
        "Black": "/img/marker-map-black.svg",
    };
  const markers = marker_items.length ? marker_items.map((marker, idx) => {
    let lat = Number(marker.lat);
    let lng = Number(marker.lng);
    let marker_color = marker.markercolor;
    if(marker_color in markerColorsMap){
        marker_img = markerColorsMap[marker_color]
    }
    let iconMarker = new window.google.maps.MarkerImage(
      marker_img,
      null,null,null,new window.google.maps.Size(45, 45)
    );
    return (
      <Marker icon={iconMarker} key={idx} position={{ lat: lat, lng: lng }} onClick={() => updateInfoIndex(idx)}>
        {(infoIndex == idx) && (
          <InfoWindow onCloseClick={() => updateInfoIndex(-1)}>
            <span dangerouslySetInnerHTML={{ __html: marker.content }}/>
          </InfoWindow>
        )}
      </Marker>
    )
  }) : []
  return <GoogleMap
    defaultZoom={12}
    defaultCenter={init_latlng}
    defaultOptions={{ styles: mapDark }}>
    {markers}
  </GoogleMap>
})

const ElementLocationMap = ({ data }) => {
  const element = JSON.parse(data.Data);
  var inlineStyle = {};
  if (element.PaddingTop != 0) {
    inlineStyle.paddingTop = element.PaddingTop + 'rem';
  }
  if (element.PaddingBottom != 0) {
    inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
  }
  if (element.BGColor != null) {
    inlineStyle.backgroundColor = element.BGColor;
  }

  return (
    <div id={`content-${element.ID}`} className="element elementlocationmap">
      <section className={`section`} style={inlineStyle}>
        <div className="container">
          {element.ShowTitle == 1 && (
            <h3 className="text-center mb-5"><span className="underline-7px">{element.Title}</span></h3>
          )}
          <div className="map-border mb-5">
            <GoogleMapWithMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA8uit3OG7Rs_41r52uRTY4hKJNu-5oXGs"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className="map-inner" />}
              mapElement={<div style={{ height: `100%` }} />}
              data={data.Data}
            />
          </div>
          <div
            className="font-recoleta fz-24 text-center"
            dangerouslySetInnerHTML={{ __html: element.Content }}
          />
        </div>
      </section>
      {element.LineDivider == 1 && (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      )}
    </div>
  )
}

export default ElementLocationMap